<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('oem-customers.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container class="pb-0 mb-0">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemCustomer.name"
                    dense
                    :label="$t('name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemCustomer.email"
                    dense
                    :label="$t('email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oemCustomer.device_type"
                    dense
                    :items="deviceTypeLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('device_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  v-if="isOsTypeIos"
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemCustomer.apple_email"
                    dense
                    :label="$t('apple_email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oemCustomer.product"
                    dense
                    :items="['oem', 'fleet']"
                    :label="$t('select_product_*')"
                    :rules="[rules.required]"
                    @change="
                      () => {
                        delete oemCustomer.tenant_id;
                        delete oemCustomer.user_type;
                      }
                    "
                  />
                </v-col>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="oemCustomer.tenant_id"
                    dense
                    :loading="tenantLoading"
                    :items="tenantsByProduct"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemCustomer.date_of_birth"
                    dense
                    :label="$t('date_of_birth_*')"
                    type="date"
                    :max="getCurrentDate()"
                    :rules="[rules.required, validateDateOfBirth]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemCustomer.address"
                    dense
                    :label="$t('address')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oemCustomer.gender"
                    dense
                    :items="genderLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('gender_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemCustomer.cnic"
                    dense
                    :label="$t('cnic_*')"
                    type="number"
                    :rules="[
                      rules.required,
                      rules.min_length(13),
                      rules.max_length(13),
                    ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oemCustomer.country_code"
                    dense
                    :items="countryCodeLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('country_code_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oemCustomer.phone_number"
                    dense
                    type="number"
                    :label="$t('phone_number_*')"
                    :rules="[
                      rules.required,
                      rules.startFrom(3),
                      rules.min_length(10),
                      rules.max_length(10),
                    ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oemCustomer.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <template>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="oemCustomer.user_type"
                      dense
                      :items="userTypeLists"
                      item-value="id"
                      item-text="name"
                      :label="$t('user_type_*')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    v-if="oemCustomer.user_type == 'business'"
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="oemCustomer.oem_b2b_id"
                      dense
                      :items="
                        authUser.isAdmin
                          ? oemCustomer.tenant_id
                            ? oemB2bList
                            : []
                          : oemB2bList
                      "
                      item-value="id"
                      item-text="business_name"
                      clearable
                      :label="$t('business')"
                    />
                  </v-col>
                </template>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-checkbox
                    v-model="oemCustomer.test_customer"
                    :label="
                      oemCustomer.user_type == 'business'
                        ? $t('test_driver')
                        : $t('test_customers')
                    "
                    hide-details
                    :ripple="false"
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pt-0 mt-0">
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(oemCustomer)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
// import axios from "axios";

// const base_url = process.env.VUE_APP_API_BASEURL;

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      stream: [],
      plugin: [],
      isVisibleDialog: false,
      isLoadingSave: false,
      oemCustomer: {
        make_type: "tenant",
      },
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      genderLists: [
        {
          id: "male",
          name: "Male",
        },
        {
          id: "female",
          name: "Female",
        },
      ],

      deviceTypeLists: [
        {
          id: "android",
          name: "Android",
        },
        {
          id: "ios",
          name: "IOS",
        },
      ],
      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      oemB2bList: "oemBusiness/getOEMB2BList",
    }),
    tenantsByProduct() {
      if (this.oemCustomer?.product) {
        if (this.oemCustomer.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
    userTypeLists() {
      const arr = [];
      arr.push({
        id: "individual",
        name: "Individual",
      });
      if (
        (this.authUser.isAdmin && this.oemCustomer.product == "oem") ||
        this.authUser.user_nature == "oem"
      ) {
        arr.push({
          id: "business",
          name: "Business",
        });
      }
      arr.push({
        id: "internal",
        name: "Internal",
      });
      return arr;
    },
    isOsTypeIos() {
      return this.oemCustomer.device_type == "ios";
    },
  },

  watch: {
    "oemCustomer.device_type": {
      handler: async function (value) {
        if (value === "android") {
          this.oemCustomer.apple_email = null;
        }
      },
    },
    "oemCustomer.tenant_id": {
      handler: async function (value) {
        if (this.oemCustomer.user_type == "business") {
          await this.$store.dispatch("oemBusiness/list", { tenant_id: value });
        }
      },
    },
    "oemCustomer.user_type": {
      handler: async function () {
        this.oemCustomer.oem_b2b_id = undefined;
        await this.$store.dispatch("oemBusiness/list", {
          tenant_id: this.oemCustomer.tenant_id,
        });
      },
    },
  },
  async mounted() {
    if (this.authUser.isAdmin) {
      // await this.$store.dispatch("tenantsManagement/list", {
      //   is_active: true,
      //   product_name: "OEM",
      // });
    } else {
      this.oemCustomer.product = this.authUser.user_nature;
    }
  },
  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(oemCustomer) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        let params = { oemCustomer, filter: { ...this.filterOptions } };

        // if (this.authUser.user_nature == "fleet") {
        //   params.oemCustomer.user_type = "individual";
        // }
        // oemCustomer.tenant_id = this.authUser.id;
        await this.$store
          .dispatch("oemCustomers/add", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
